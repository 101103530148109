import React, { useState } from 'react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedinIn, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight, faChevronDown, faExternalLinkAlt, faMicrophoneLines } from '@fortawesome/free-solid-svg-icons'
import { AnimateHeightBySacrificingPerf } from 'react-anim-kit'

import useViewport from '../../hooks/useViewport'
import Image from '../../components/Image'

import styles from './Footer.module.scss'

import Config from '../../config'

import breakevenLogo from '../../public/breakeven_ltd.svg'
import flag from '../../public/flag.jpg'

const Footer = ({}) => {
  const [openSection, setOpenSection] = useState('')

  const viewport = useViewport()

  const handleOnClick = (href: string, name: string) => {
    window.umami?.track(`Social link: ${name}`)

    window.open(href, '_blank')
  }

  return (
    <div className={styles.footer}>
      <div className={styles.footerTop}>
        <div className={[styles.footerTopContainer, 'container'].join(' ')}>
          <div className={styles.footerTopFirst}>
            <div className={styles.footerTopLogo}>
              <Link href="/">
                <a>
                  <Image src={breakevenLogo} alt="Breakeven" width={300} height={75} />
                </a>
              </Link>
            </div>
            <div className={styles.footerTopSocial}>
              <a href="/social/links/instagram" target="_blank" onClick={evt => {
                evt.preventDefault()
                
                handleOnClick('/social/links/instagram', 'Instagram')
              }}>
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="/social/links/x" target="_blank" onClick={evt => {
                evt.preventDefault()
                
                handleOnClick('/social/links/x', 'X')
              }}>
                <FontAwesomeIcon icon={faXTwitter} />
              </a>
              <a href="/social/links/facebook" target="_blank" onClick={evt => {
                evt.preventDefault()
                
                handleOnClick('/social/links/facebook', 'Facebook')
              }}>
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="/social/links/linkedin" target="_blank" onClick={evt => {
                evt.preventDefault()
                
                handleOnClick('/social/links/linkedin', 'LinkedIn')
              }}>
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
              <Link href="/podcast">
                <a>
                  <FontAwesomeIcon icon={faMicrophoneLines} />
                </a>
              </Link>
            </div>
            <div className={styles.weSupport}>
              <Image src={flag} alt="We support" width={53} height={30} />
            </div>
          </div>
          <div className={styles.footerTopLast}>
            {viewport.width > 767 ? (
              <>
                {Config.siteMap.main.map((x, idx) => (
                  <div key={idx} className={styles.footerTopNavSection}>
                    <div className={styles.footerTopNavSectionTitle}>{x.title}</div>
                    {x.items.map((i, idx) => (
                      i.external ? (
                        <a key={idx}  href={i.link} target="_blank" className={styles.footerTopNavSectionLink}>
                          <span>{i.title}</span>
                          <FontAwesomeIcon icon={faExternalLinkAlt} />  
                        </a>
                      ) : (
                        <Link key={idx} href={i.link}>
                          <a className={styles.footerTopNavSectionLink}>{i.title}</a>
                        </Link>
                      )
                    ))}
                    {idx === 0 &&
                      <>
                        <div className={styles.weSupport}>
                          <span className={styles.title}>We support</span>
                          <Image src={flag} alt="We support" width={45} height={25} />
                        </div>
                      </>
                    }
                    {idx === (Config.siteMap.main.length - 1) &&
                      <>
                        <div className={styles.justGiving}>
                          <a href="https://widgets.justgiving.com/Button/Redirect?p=eyJJZCI6ImVhMzU3ODM4LTQxYWMtNGVjOS04Yjc4LWQ4ODZiYWYyYWU2NiIsIkNoYXJpdHlJZCI6MzMzMjI2NywiU2l6ZSI6InMiLCJSZWZlcmVuY2UiOiJ3ZWJzaXRlIiwiVHlwZSI6IkRvbmF0ZSJ9" target="_blank">
                            <img src="https://widgets.justgiving.com/Button?p=eyJJZCI6ImVhMzU3ODM4LTQxYWMtNGVjOS04Yjc4LWQ4ODZiYWYyYWU2NiIsIkNoYXJpdHlJZCI6MzMzMjI2NywiU2l6ZSI6InMiLCJSZWZlcmVuY2UiOiJ3ZWJzaXRlIiwiVHlwZSI6IkRvbmF0ZSJ9&v=1" alt="Just Giving" />
                          </a>
                        </div>
                      </>
                    }
                  </div>
                ))}
              </>
            ) : (
              <>
                {Config.siteMap.main.map((x, idx) => (
                  <div key={idx} className={styles.footerTopNavSectionMobile} onClick={evt => setOpenSection(prev => prev === x.title ? '' : x.title)}>
                    <div className={[styles.footerTopNavSectionTitle, openSection === x.title ? styles.footerTopNavSectionTitleOpen : ''].join(' ')}>
                      <div>{x.title}</div>
                      <FontAwesomeIcon icon={faChevronDown} width={20} height={20} />
                    </div>
                    <AnimateHeightBySacrificingPerf
                      shouldChange={openSection === x.title}
                      transition="height 0.3s"
                    >
                      {openSection === x.title &&
                        <div className={styles.footerTopNavSectionLinks}>
                          {x.items.map((i, idx) => (
                            i.external ? (
                              <a key={idx}  href={i.link} target="_blank" className={styles.footerTopNavSectionLink}>
                                <div>{i.title}</div>
                                <FontAwesomeIcon icon={faExternalLinkAlt} width={20} height={20} />
                              </a>
                            ) : (
                              <Link key={idx} href={i.link}>
                                <a className={styles.footerTopNavSectionLink}>
                                  <div>{i.title}</div>
                                  <FontAwesomeIcon icon={faArrowRight} width={20} height={20} />
                                </a>
                              </Link>
                            )
                          ))}
                        </div>
                      }
                    </AnimateHeightBySacrificingPerf>
                  </div>
                ))}    
                <div className={styles.justGiving}>
                  <a href="https://widgets.justgiving.com/Button/Redirect?p=eyJJZCI6ImVhMzU3ODM4LTQxYWMtNGVjOS04Yjc4LWQ4ODZiYWYyYWU2NiIsIkNoYXJpdHlJZCI6MzMzMjI2NywiU2l6ZSI6InMiLCJSZWZlcmVuY2UiOiJ3ZWJzaXRlIiwiVHlwZSI6IkRvbmF0ZSJ9" target="_blank">
                    <img src="https://widgets.justgiving.com/Button?p=eyJJZCI6ImVhMzU3ODM4LTQxYWMtNGVjOS04Yjc4LWQ4ODZiYWYyYWU2NiIsIkNoYXJpdHlJZCI6MzMzMjI2NywiU2l6ZSI6InMiLCJSZWZlcmVuY2UiOiJ3ZWJzaXRlIiwiVHlwZSI6IkRvbmF0ZSJ9&v=1" alt="Just Giving" />
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className={styles.footerBottomContainer}>
          <div className={styles.footerBottomLeft}>
            <div style={{
              marginBottom: 8
            }}>
              <a href="https://www.counselling-directory.org.uk/?gad_source=1&gclid=EAIaIQobChMI9cHUhMeRjAMV-ptQBh06lhs9EAAYASAAEgKiO_D_BwE" target="_blank">Counselling Directory - Find a Counsellor Near You</a>
            </div>
            <div>
              © Copyright {(new Date()).getFullYear()}
              &nbsp;&nbsp;|&nbsp;&nbsp;
              Breakeven Limited
              &nbsp;&nbsp;|&nbsp;&nbsp;
              Charity Number: 1158156
            </div>
          </div>
          {/*<div className={styles.footerBottomRight}><Link href="/privacy-policy"><a>Privacy Policy</a></Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link href="/cookie-policy"><a>Cookie Policy</a></Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link href="/terms"><a>Terms</a></Link></div>*/}
          <div className={styles.footerBottomRight}>
            <Link href="/complaints">
              <a>Complaints</a>
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link href="/privacy-policy">
              <a>Privacy</a>
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link href="/terms">
              <a>Terms</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer